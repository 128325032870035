<template>
  <!--begin::Container-->
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div
      v-if="$apollo.data.identifierOrder && order"
      class="main main-raised"
      style="background:#fff;"
    >
      <div class="container">
        <div class="text-lg-right">
          <v-btn elevation="9" rounded color="grey" @click="$router.go(-1)">
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="container">
            <div>
              Sold To:
              {{ identifierOrder.masterOrder.buyerCompany.company_name }}
            </div>
            <div class="row ">
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Billing Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                    <p class="mb-2">
                      {{ identifierOrder.billToAddress.company_name }}<br />
                      {{ identifierOrder.billToAddress.address_line1 }}<br />
                      {{ identifierOrder.billToAddress.address_line2 }}<br />
                      {{ identifierOrder.billToAddress.city }}
                      {{ identifierOrder.billToAddress.state }}
                      {{ identifierOrder.billToAddress.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      {{ identifierOrder.billToAddress.phone }}
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Shipping Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                    <p class="mb-2">
                      {{ identifierOrder.shipToAddress.company_name }}<br />
                      {{ identifierOrder.shipToAddress.address_line1 }}<br />
                      {{ identifierOrder.shipToAddress.address_line2 }}<br />
                      {{ identifierOrder.shipToAddress.city }}
                      {{ identifierOrder.shipToAddress.state }}
                      {{ identifierOrder.shipToAddress.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      {{ identifierOrder.shipToAddress.phone }}
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->

              <div class="col-lg-4">
                <v-card flat>
                  <ul class="list-unstyled mb-0">
                    <li>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Purchase Order #:</span
                        >
                        {{ identifierOrder.po }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Payment Type:</span
                        >
                        NET TERMS
                      </p>
                    </li>
                  </ul>
                </v-card>
              </div>
              <!-- end col -->
            </div>
            <div class="row  ">
              <div class="col-lg-12">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header card-header-tabs-line">
                    <div class="card-title">
                      <h3 class="card-label">
                        Items from Order #{{ $route.params.id }}
                      </h3>
                    </div>
                    <div class="card-toolbar"></div>
                  </div>

                  <div class="card-body">
                    <!--begin: Datatable-->
                    <v-form ref="form" v-model="valid">
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">Line ID</th>
                            <th style="width:280px;">SKU</th>
                            <th>Thumb</th>
                            <th style="width:280px;">Item</th>
                            <th style="width:100px;">Qty Ordered</th>
                            <th style="width:100px;">Qty Available</th>
                            <th style="width:100px;">Qty Shipped</th>
                            <th class="text-left">Price</th>
                            <th class="text-left">Subtotal</th>
                            <th class="text-left">Qty to ship/invoice</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, idx) in order.orderItems"
                            :key="idx"
                          >
                            <td>{{ item.line }}</td>
                            <td>{{ item.sku }}</td>
                            <td>
                              <v-img
                                class="d-flex align-self-center mr-3 rounded-circle"
                                :src="
                                  item.url != null
                                    ? baseurl + item.url
                                    : '/media/rollerskate.png'
                                "
                                alt="Generic placeholder image"
                                width="50"
                                height="50"
                              >
                              </v-img>
                            </td>
                            <td>
                              {{ item.productName }}
                              <span v-if="item.name != 'default'"
                                >({{ item.name }})</span
                              >
                            </td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.quantityAvailable }}</td>
                            <td>
                              {{ item.quantityShipped }}
                            </td>
                            <td>{{ item.unitPrice | dollar_format }}</td>
                            <td>
                              {{
                                (item.unitPrice * item.quantityToShip)
                                  | dollar_format
                              }}
                            </td>
                            <td>
                              <v-text-field
                                v-model.number="item.quantityToShip"
                                type="number"
                                label="qty to ship"
                                dense
                                outlined
                                min="0"
                                :max="item.quantity - item.quantityShipped"
                                :rules="rules(item)"
                                style="width: 100px"
                              ></v-text-field>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-container class="grey lighten-5">
                        <v-row
                          v-for="(tracking, index) in trackingInfos"
                          :key="index"
                        >
                          <v-col>
                            <v-select
                              v-model="tracking.carrier"
                              :items="carriers"
                              :rules="required"
                              label="Shipping Carrier"
                            ></v-select>
                          </v-col>
                          <v-col v-if="tracking.carrier === 'Freight'">
                            <v-text-field
                              v-model="tracking.customName"
                              :rules="required"
                              label="Carrier Name"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="tracking.number"
                              :rules="required"
                              label="Tracking Number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-btn
                              v-if="index > 0"
                              small
                              text
                              @click="removeTrackInfo(index)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-btn plain outlined small @click="addTrackingInfo">
                            <v-icon>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </v-row>
                      </v-container>
                      <div class="text-lg-right">
                        <v-btn
                          :disabled="
                            order.orderItems.length === 0 || areAllZero
                          "
                          @click="onUpdate"
                        >
                          Ship & Invoice
                        </v-btn>
                      </div>
                    </v-form>
                  </div>
                </v-card>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import GetOrderByIdentifier from "@/graphql/queries/GetOrderByIdentifier.gql";
import CreateFullShipment from "@/graphql/mutations/CreateFullShipment.gql";

const emptyInfo = { carrier: null, customName: null, number: null };
export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    quantityToShip: [],
    order: null,
    valid: true,
    carriers: ["Fedex", "UPS", "USPS", "Freight"],
    trackingInfos: [{ ...emptyInfo }],
    required: [(v) => !!v || "This field is required"],
  }),
  apollo: {
    identifierOrder: {
      query: GetOrderByIdentifier,
      variables() {
        return {
          identifier: this.$route.params.id,
        };
      },
    },
  },
  computed: {
    areAllZero() {
      if (!this.order) {
        return true;
      }
      return this.order.orderItems.every(
        (o) =>
          o.quantityToShip === "0" ||
          o.quantityToShip === 0 ||
          o.quantityToShip === ""
      );
    },
  },
  watch: {
    identifierOrder(newValue) {
      this.order = {
        ...newValue,
        orderItems: newValue.orderItems.map((i) => {
          const quantityShipped = i.shipmentItems.reduce(
            (a, c) => a + c.quantity,
            0
          );
          const quantityAvailable = i.quantity - quantityShipped;

          return {
            ...i,
            quantityToShip: quantityAvailable, // variable that will be sent as part of the request.
            quantityShipped,
            quantityAvailable,
          };
        }),
      };
    },
  },
  methods: {
    addTrackingInfo() {
      this.trackingInfos = [...this.trackingInfos, [{ ...emptyInfo }]];
    },
    rules(item) {
      return [
        (v) =>
          (v !== "" && v !== null && v !== undefined) ||
          "This field is required",
        (v) =>
          v <= item.quantity - item.quantityShipped ||
          "Should not be above shipable quantity.",
        (v) => v >= 0 || "Should not be below 0",
      ];
    },
    removeTrackInfo(index) {
      this.trackingInfos.splice(index, 1);
    },
    async onUpdate() {
      // guard against empty field.
      if (!this.$refs.form.validate()) {
        Swal.fire({
          title: "",
          text: "Please check all fields are filled correctly.",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
        return;
      }
      if (this.order.orderItems.some((o) => o.quantityToShip === "")) {
        Swal.fire({
          title: "",
          text: "Empty",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
        return;
      }
      const order = this.order;
      const shipment = {
        billToAddress: order.billToAddress.id,
        shipToAddress: order.shipToAddress.id,
        order: order.id,
        trackingInfos: this.trackingInfos,
        vendorCompany: order.vendorCompany.id,
      };
      const shipmentItems = this.order.orderItems.map((o) => ({
        quantity: parseInt(o.quantityToShip),
        orderItem: o.id,
      }));
      try {
        await this.$apollo.mutate({
          mutation: CreateFullShipment,
          variables: { shipment, shipmentItems },
        });
        await this.$apollo.queries.identifierOrder.refetch();
        this.$refs.form.resetValidation();
        await Swal.fire({
          title: "",
          text: "Updated successfully.",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
        this.$router.push("/market/order-detail/" + this.$route.params.id);
      } catch (error) {
        await this.$apollo.queries.identifierOrder.refetch();
        this.$refs.form.resetValidation();
        await Swal.fire({
          title: "",
          text: "Error, please try again.",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },
  },
};
</script>

<style scoped>
.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
  font-family: "Lato", sans-serif;
}
.v-application .title {
  font-size: 2.75rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Lato", sans-serif;
}

.page-header {
  height: 100vh;
  background-position: 50%;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-raised {
  margin: -180px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.main {
  background: #eee;
  position: relative;
  z-index: 3;
}
.md-button:not(.md-just-icon):not(.md-btn-fab):not(.md-icon-button):not(.md-toolbar-toggle)
  .md-ripple {
  padding: 12px 30px;
}
.md-button .md-ripple {
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.md-ripple {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(circle, #fff 100%, #000 0);
}
</style>
